import { apiAction, axiosApiInstance, dynamoAction, getRefreshToken, reDispatch } from "store/middleware/api"
import { getSKfromType } from "utils/geenrateIds"
import { getUrlParam } from "utils/history"
import store from "store/index"
import { setApiResponse } from "store/modules/auth"
import { setDataItems } from "store/modules/dataView"
import { SET_DICTIONARY } from "store/modules/backData"
import { ADD_ITEMS, SET_ITEMS, ADD_PK_ITEMS_WITHOUT_DUPLICATES } from "store/modules/dataView"
import {
  clearLoadingIndicator,
  setLoadingIndicator,
  SET_CRT_FORM_ITEM
} from "store/modules/page"
import { SET_SEARCH_RESULTS } from "store/modules/shop"
import moment from "moment"
import { toastr } from "react-redux-toastr"
import { printError } from "utils/printError"
import { noBrandFilter } from "utils/filterUtils"

//TODO: move to a normal place :)
// *** Search operators
// EQUAL("=="),
// NOT_EQUAL("!="),
// LESS("<"),
// LESS_OR_EQUAL("<="),
// GREATER(">"),
// GREATER_OR_EQUAL(">="),
// BETWEEN("between"),
// NOT_BETWEEN("!between"),
// LIKE("~"),
// NOT_LIKE("!~"),
// STARTS_WITH("starts_with"),
// ENDS_WITH("ends_with"),
// EMPTY("empty"),
// NOT_EMPTY("!empty"),
// SUBQUERY_IN("subquery_in"),
// NOT_SUBQUERY_IN("!subquery_in"),
// IN("in"),
// NOT_IN("!in"),

// OR("or"),
// AND("and");

const productSearchData = {
  firstRow: 1000,
  pageSize: 10,
  sortField: null,
  sortAsc: false,
  filters: [],
  filterText: "",
  searchTerm: null,
  jsonLogicText: null,
  jsonLogic: {
    operation: "OR",
    children: [],
    field: null,
    value: null
  }
}

// selectedFilters: {
//   'FLT-SMaaaa00': [ '2', '3']
// }

export const filterKeys = {
  "FLT-CATEG001": "categoryTreeCode",
  "FLT-SMaaaa00": "brandName",
  "FLT-SMaaaa11": "brandName",
  "FLT-GeneralStatus01": "status",
  "FLT-CompanyStatus": "status",
  "FLT-OrderStatus": "status",
  "FLT-PersPosition": "position",
  "FLT-b2bProductStatus": "status",
  "FLT-RoleStatus": "status",
  "FLT-Applicability": "userDirectory",
  "FLT-Source": "source",
  "FLT-Source-Client": "source",
  "FLT-InvStatus": "status",
  "FLT-CLIENT": "clientId",
  "FLT-UserType": "type",
  "FLT-CLIENT-ID": "organizationId",
  "FLT-Agent": "agentId",
  "FLT-Ranking": "ranking"
}

const isFilterHashed = {
  "FLT-Agent": true,
  "FLT-CLIENT-ID": true,
}

//Hint: this is a workaround solution, until API will have only one type of search (at least as request inputs)
export const createClassicFilters = (filters = false, qsFilters = null) => {
  

  if (filters) return filters
  
  
  
  
  const filterIds = [
    "FLT-SMaaaa11",
    "FLT-SMaaaa00",
    "FLT-GeneralStatus01",
    "FLT-CompanyStatus",
    "FLT-InvStatus",
    "FLT-RoleStatus",
    "FLT-Applicability",
    "FLT-Source",
    "FLT-Source-Client",
    "FLT-PersPosition",
    "FLT-OrderStatus",
    "FLT-b2bProductStatus",
    "FLT-UserType",
    "FLT-Agent"
  ]


  let state = store.getState()
  let result = []

  const isQsFiltersEmpty = !qsFilters || Object.keys(qsFilters || {})?.length === 0
  const selectedFilters = (!isQsFiltersEmpty && qsFilters) || state?.dataView?.selectedFilters
  

  //next section is used by the brandName >> version 1 for general multiselect...
  for (const filterId in selectedFilters) {
    const filterValues = selectedFilters?.[filterId]

    if (filterId === "FLT-b2bProductStatus" && filterValues?.length) {
      result?.push?.({
        beginParenthesis: "(",
        field: filterKeys?.[filterId],
        relation: "=",
        value: filterValues?.join?.(""),
        operator: "AND",
        endParenthesis: ")", 
        hashed: isFilterHashed?.[filterId] ? true : false,
      })
    } else if (filterId === "FLT-CLIENT-ID" && filterValues?.length) {
      result?.push?.({
        beginParenthesis: "(",
        field: filterKeys?.[filterId],
        relation: "in",
        value: filterValues?.join?.(","),
        operator: "AND",
        endParenthesis: ")", 
        hashed: isFilterHashed?.[filterId] ? true : false,
      })
    } else if (filterId === "FLT-CATEG001") {

      result?.push?.({
        beginParenthesis: "(",
        field: filterKeys?.[filterId],
        relation: "startswith",
        value: filterValues?.join?.(","),
        operator: "AND",
        endParenthesis: ")"
      })

    } else if(filterValues?.length){

      if(filterValues?.includes?.(noBrandFilter)){
        
        result?.push?.({
          beginParenthesis: "(",
          field: filterKeys?.[filterId],
          relation: "isnull",
          value: null,
          operator: "AND",
          endParenthesis: ")"
        })

      } else if (filterIds?.includes?.(filterId)) {
        
        result?.push?.({
          beginParenthesis: "(",
          field: filterKeys?.[filterId],
          relation: "in",
          value: filterValues?.join?.(","),
          operator: "AND",
          endParenthesis: ")",
          hashed: isFilterHashed?.[filterId] ? true : false,
        })
      }
      if ("FLT-CLIENT" === filterId) {
        
        result?.push?.({
          beginParenthesis: "(",
          field: filterKeys?.[filterId],
          relation: "=",
          value: filterValues?.join?.(""),
          hashed: "true",
          endParenthesis: ")"
        })
      }

    }

    // "filters": [
    //   {"field": "status", "relation": "in", "value": "ACTIVE"},
    //   {"beginParenthesis": "(", "field": "name", "relation": "~", "value": "denes", "operator": "OR"},
    //   {"field": "name", "relation": "~", "value": "anon", "endParenthesis": ")"}
    // ],
  }


  

  return result
  
}
const processGuestSort = () => {
  let state = store.getState()
  const selectedFilters = state?.dataView?.selectedFilters
  const guestSortFilter = selectedFilters?.["FLT-sort00p1"]?.[0]
  const guestOrderCreateDateFilter = selectedFilters?.["FLT-sortOderDate"]?.[0]
  let [sortField, sortAsc] = guestSortFilter
    ? guestSortFilter?.split?.("|")
    : guestOrderCreateDateFilter
    ? guestOrderCreateDateFilter?.split?.("|")
    : [null, null]
  sortAsc = Boolean(sortAsc === "true")
  return [sortField, sortAsc]
}
export const createFilters = ({ qsFilters }) => {
  let state = store.getState()
  let result = []
  const selectedFilters = {
    ...(state?.dataView?.selectedFilters ?? {}),
    ...(qsFilters ?? {})
  }
  //next section is used by the brandName >> version 1 for general multiselect...
  for (const filterId in selectedFilters) {
    const filterValues = selectedFilters?.[filterId]
    if (
      filterValues?.length > 0 &&
      (filterId === "FLT-SMaaaa11" || filterId === "FLT-SMaaaa00")
    ) {
      result?.push?.(
        filterValues?.includes?.(noBrandFilter)
          ? {
              operation: "OR",
              children: [
                {
                  operation: "EMPTY",
                  children: [
                    {
                      operation: null,
                      children: null,
                      field: "brandName",
                      value: noBrandFilter
                    }
                  ],
                  field: null,
                  value: null
                },
                {
                  operation: "IN",
                  children: [
                    {
                      operation: null,
                      children: null,
                      field: filterKeys?.[filterId],
                      value: filterValues?.join?.(",")
                    }
                  ],
                  field: null,
                  value: null
                }
              ],
              field: null,
              value: null
            }
          : {
              operation: "IN",
              children: [
                {
                  operation: null,
                  children: null,
                  field: filterKeys?.[filterId],
                  value: filterValues?.join?.(",")
                }
              ],
              field: null,
              value: null
            }
      )
    }

    if (
      filterValues?.length > 0 &&
      [
        "FLT-GeneralStatus01",
        "FLT-RoleStatus",
        "FLT-Applicability"
      ]?.includes?.(filterId)
    ) {
      result?.push?.({
        //temp disable untill we get the logic working fine
        operation: "IN",
        children: null,
        field: filterKeys?.[filterId],
        value: filterValues?.join?.(",")
      })
    }
    if (filterValues?.length > 0 && filterId === "FLT-Ranking") {
      result?.push?.({
        operation: "OR",
        children: [
          {
            operation: "EQUAL",
            children: [
              {
                operation: null,
                children: null,
                field: filterKeys?.[filterId],
                value: filterValues?.[0]
              }
            ],
            field: null,
            value: null
          }
        ],
        field: null,
        value: null
      })
    }

    if (
      filterValues?.length > 0 &&
      ["FLT-b2bProductStatus"]?.includes?.(filterId)
    ) {
      //'FLT-CLIENT'
      result?.push?.({
        //temp disable untill we get the logic working fine
        operation: "IN",
        children: [
          {
            operation: null,
            children: null,
            field: filterKeys?.[filterId],
            value: filterValues?.join?.(",")
          }
        ],
        field: null,
        value: null
      })
    }
  }

  //next section looks for category filter.
  //work in progress
  // const treeCode = selectedFilters?.["FLT-PCATEG01"] ?? selectedFilters?.["FLT-PCATEG01"] ?? selectedFilters?.["FLT-PCATEG00"]
  // const treeCode = undefined
  const treeCode =
    selectedFilters?.["FLT-CATMEGA1"]?.[0] ??
    selectedFilters?.["FLT-CATEG001"]?.[0]

  if (treeCode)
    result?.push?.({
      operation: "STARTS_WITH",
      children: [
        {
          operation: null,
          children: null,
          field: "categoryTreeCode",
          value: treeCode
        }
      ],
      field: null,
      value: null
    })
  return result
}

export const searchStuff = ({
  type = "product",
  qsFilters,
  sortAsc = true,
  sortField,
  firstRow = 0,
  pageSize = 25,
  page,
  searchTerm,
  reload,
  target,
  dictionary,
  filters = false
}) => {   
  let state = store.getState()
  const nextAction =
    target === "menuSearch"
      ? SET_SEARCH_RESULTS
      : reload
      ? SET_ITEMS
      : ADD_ITEMS
  let [guestSortField, guestSortAsc] = processGuestSort()


  const chooseSortField = () => {

    if(
      state?.dataView?.sort?.[0] === 'relevance' || 
      guestSortField === 'relevance'
    ){
      return null // The back-end expects null for sorting by relevance.
    } 

    if (type === "organization/webshop-client") return null
    if (type === "uniprest/user") return null


    if (type === "location") return sortField
    if (type === "public/organization/workplace") return null
    if (state?.dataView?.sort?.[0] !== undefined)
      return state?.dataView?.sort?.[0]
    if (guestSortField !== undefined) return guestSortField
    return sortField
  }

  const chooseSortAsc = () => {
    if (type === "location") return sortAsc
    if (type === "public/organization/workplace") return null
    if (state?.dataView?.sort?.[1] !== undefined)
      return state?.dataView?.sort?.[1]
    if (guestSortAsc !== undefined) return guestSortAsc
    return sortAsc
  }

  if (type === "location") {
    guestSortAsc = sortAsc
    guestSortField = sortField
  }
  if (type === "public/organization/workplace") {
    guestSortAsc = null
    guestSortField = null
  }

  let itemId
  if (["stock"]?.includes?.(type)) {
    // exception to add some Id in the search url
    itemId = getUrlParam("id")
    if (!itemId) return { type: "a" }
  }


  /**
   * Decides whether the API should return the number of the results.
   * This is the count of all the results. Not to be confused with the
   * size of the result array (which is just a slice of the full list,
   * limited to pageSize).
   */
  const isResultCountNeeded = () => {
    if(type === "public/product/goods/shop") return true
    if(type === "product/goods/shop") return true
    if(type === "product/goods") return true
    if(type === "global-products-table") return true
    return false
  }

  const calculateViewId = {
    "product/goods/shop" : "java-product-client",
    "public/product/goods/shop" : "notLogged-java-product-client"
  }

  const decideFirstRow = () => {

    if(page) return pageSize * (page - 1)

    if(reload) return 0

    return state?.dataView?.lastItemHint || 0

  }

  const finalFilters = target === "menuSearch" ? [] : createClassicFilters(filters, qsFilters)

  return apiAction({
    endPoint: `/${type}/search` + (itemId ? `/${itemId}` : ``),
    method: "POST",
    data: {
      ...productSearchData,
      sortAsc: chooseSortAsc(),
      sortField: chooseSortField(),
      firstRow: decideFirstRow(),
      pageSize,
      countResult: isResultCountNeeded(),
      searchTerm: searchTerm || state?.dataView?.searchString,
      filters: finalFilters,
      jsonLogic: {
        operation: "AND",
        children: target === "menuSearch" ? [] : createFilters({ qsFilters }),
        field: null,
        value: null
      }
    },
    onSuccess: (data, dispatch) => {
      dispatch(setApiResponse(data))
      const newLastItemHint =
        Number(reload ? 0 : state?.dataView?.lastItemHint ?? 0) +
        data?.items?.length
      if (dictionary) {
        let payload = {}
        payload[dictionary] = data?.items
        return {
          type: SET_DICTIONARY,
          payload
        }
      } else {

        if(shouldIgnoreSearchApiCall(type)) {
          /**
           * When the user is looking at a list, that list is fetched from the API and 
           * stored in 'state.dataView.items'.
           * 
           * Since almost EVERY kind of list is stored here, this value is often 
           * overwritten by different list-API responses. Typically when the user opens 
           * a page and quickly navigates to another one, each page makes an API call. 
           * Sometimes the second API responds quicker than the first one (especially 
           * if the first one is delayed (to prevent fetching every keypress in the 
           * text-based search)). If the first API finishes last, the eventual saved 
           * value will be the wrong one (the first API response instead of the second one). 
           * 
           * The specific case of this, the productList overwriting the orderList was 
           * reported, investigated and prevented. (UNI-1243)
           */
          console.log('The productList API was about to overwrite the orderList data. Action prevented. ')
          return { type: "a" }
        }else {
          return {
            type: nextAction,
            payload: {
              items: data?.items,
              lastItemHint: data?.items?.length === pageSize && newLastItemHint,
              pageSize,
              count: data?.count,
              controlPageViewId: calculateViewId?.[type] || ""
            }
          }
        }

        
      }
        
    },
    onFailure: (error) => {
      printError("searchStuff() apiAction()", error)
      return {
        type: "a"
      }
    }
  })
}


const shouldIgnoreSearchApiCall = type => {
  
  const currentPathName = window?.location?.pathname

  const isProductApi = type === 'product/goods/shop' || type === 'public/product/goods/shop'
  const isProductListPage = currentPathName?.startsWith?.('/shop/productlist')

  return isProductApi && !isProductListPage
}


export const loadExcelProducts = (sourceId) =>
  reDispatch((dispatch) => {
    dispatch(setLoadingIndicator(sourceId, true))
    //dispatch(clearLoadingIndicator(sourceId))

    const state = store?.getState()
    return apiAction({
      endPoint: "/product/goods/shop/export",
      method: "POST",
      responseType: "blob",
      data: {
        format: "XLSX",
        language: "RO",
        entityCode: "productShop",
        queryCriteria: {
          firstRow: 0,
          pageSize: 0, //state?.dataView?.items?.length ?? 10,//10,
          filters: [], //createClassicFilters(),
          searchTerm: state?.dataView?.searchString ?? null, //null,
          jsonLogic: {
            //null
            operation: "AND",
            children: createFilters(),
            field: null,
            value: null
          }
        },
        columns: [
          "code",
          //"status",
          "name",
          "externalCode",
          "ranking",
          "brandName",
          "priceListNet",
          "priceListGross",
          "currency",
          "unitOfMeasure",
          "tagList" //: [],
        ]
      },
      onSuccess: (data) => {
        dispatch(clearLoadingIndicator(sourceId))
        const downloadLink = window.document.createElement("a")
        downloadLink.href = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          })
        )
        downloadLink.download = `Lista_produse_B2B_${moment(Date.now()).format(
          "DD.MM.YYYY-HH:MM:SS"
        )}.xlsx`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
        return {
          type: "a"
        }
      },
      onFailure: (data) => {
        printError("loadExcelProducts()", data)
        dispatch(clearLoadingIndicator(sourceId))

        toastr.error(
          "Momentan produsele nu au putut fi descarcate, incearca mai tarziu"
        )
        return {
          type: "a"
        }
      }
    })
  })

export const downloadShoppingCart = (type, id) => {
  return apiAction({
    endPoint: `/basket/my-basket/export?format=${computedParam(type)}`,
    method: "GET",
    responseType: "blob",
    onSuccess: (data, dispatch) => {
      const downloadLink = window.document.createElement("a")
      downloadLink.href = window.URL.createObjectURL(
        new Blob([data], computedType(type))
      )
      downloadLink.download = `Cos_cumparaturi_B2B_${moment(Date.now()).format(
        "DD.MM.YYYY-HH:MM:SS"
      )}.${type === "excel" ? "xlsx" : "pdf"}`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      return {
        type: "a"
      }
    },
    onFailure: (d, dispatch) => {
      printError("downloadShoppingCart()", d)
      toastr.error(
        "Momentan cosul de cumparaturi nu a putut fi descarcat, incearca mai tarziu"
      )
      return {
        type: "a"
      }
    }
  })
}
const computedType = (type) => {
  switch (type) {
    case "pdf":
      return { type: "application/pdf" }
    case "excel":
      return {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      }
    default:
      return { type: "application/pdf" }
  }
}
const computedParam = (type) => {
  switch (type) {
    case "pdf":
      return "PDF"
    case "excel":
      return "XLSX"
    default:
      return "PDF"
  }
}
export const loadDldOrders = (type, id, code, itemType) => {
  return apiAction({
    // endPoint: `/order/report-export-${type}/${id ? id : ""}`,//Vlea3l
    endPoint: `/${itemType}/export/${id ? id : ""}?format=${computedParam(
      type
    )}`,
    method: "GET",
    responseType: "blob",
    onSuccess: (data, dispatch) => {
      const downloadLink = window.document.createElement("a")
      downloadLink.href = window.URL.createObjectURL(
        new Blob([data], computedType(type))
      )
      downloadLink.download = `Order_${code}_${moment(Date.now()).format(
        "DD.MM.YYYY-HH:MM:SS"
      )}.${type === "excel" ? "xlsx" : "pdf"}`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      return {
        type: "a"
      }
    },
    onFailure: (d, dispatch) => {
      printError("loadDldOrders()", d)
      toastr.error(
        "Momentan comanda nu a putut fi descarcata, incearca mai tarziu"
      )
      return {
        type: "a"
      }
    }
  })
}

export const loadDldOffers = (id, code, showPrice, isExcel) => {
  return apiAction({
    endPoint: `/customer-offer/report-export/${
      id ? id : ""
    }?price=${showPrice}&format=${isExcel ? "XLSX" : "PDF"}`, ///customer-offer/report-export/Wr80r4
    method: "GET",
    responseType: "blob",
    onSuccess: (data, dispatch) => {
      const downloadLink = window.document.createElement("a")
      downloadLink.href = window.URL.createObjectURL(
        new Blob([data], { type: `${isExcel ?
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :
          "application/pdf"
        }` })
      )
      downloadLink.download = `Offer_${code}_${moment(Date.now()).format(
        "DD.MM.YYYY-HH:MM:SS"
      )}.${isExcel ? "xlsx" : "pdf"}`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      return {
        type: "a"
      }
    },
    onFailure: (d, dispatch) => {
      printError("loadDldOffers()", d)
      toastr.error(
        "Momentan oferta nu a putut fi descarcata, incearca mai tarziu"
      )
      return {
        type: "a"
      }
    }
  })
}
const setDataViewItems = (items) => {
   return {
          type: SET_ITEMS,
          payload: {
            items: items,
          }
        }
}
const addDataViewItems = (items) => {
  return {
         type: ADD_PK_ITEMS_WITHOUT_DUPLICATES,
         payload: {
           items: items,
         }
       }
}


const shouldPreventDynamoRequest = SK => SK === 'MAINTENANCE-SK'


export const loadDynamoItemsBySK = ({ SK, dictionary, shouldConcatResultsToState = false, lastEvaluatedKey = null}) => {   
  
  /**
   * Prevent requests to DynamoDB to ask if maintenance-mode is on. We now use a 
   * completely different solution for that. See utils/useShouldShowMaintenanceScreen.js
   */
  if(shouldPreventDynamoRequest(SK)) return { type: "a" }

  return dynamoAction({
    method: "QUERY",
    parameters: {
      TableName: process.env.REACT_APP_TABLE_NAME,
      KeyConditionExpression: "#pk = :pkvalue",
      IndexName: "SK-PK",
      ExclusiveStartKey: lastEvaluatedKey || undefined,
      ExpressionAttributeNames: {
        "#pk": "SK"
      },
      ExpressionAttributeValues: {
        ":pkvalue": { S: SK }
      },
      // Limit: 5,
    },
    onSuccess: (data, dispatch) => {
      if (dictionary) {
        let payload = {}
        payload[dictionary] = data?.items
        return {
          type: SET_DICTIONARY,
          payload
        }
      } 
      else {
        if(shouldConcatResultsToState){
          dispatch(addDataViewItems(data?.items))
        }else{
          dispatch(setDataViewItems(data?.items))
        }
      }
      const isThereMoreToLoad = Boolean(data.lastEvalKey)
      if(isThereMoreToLoad){
        dispatch(
          loadDynamoItemsBySK({
            SK, dictionary,
            shouldConcatResultsToState: true, 
            lastEvaluatedKey: data.lastEvalKey,
          })
        )
      }
      return {
        type: "a"
      }
    },
    onFailure: (err) => {
      printError("loadDynamoItemsBySK()", err)
      return {
        type: "a"
      }
    }
  })       
}


export const loadDynamoFormItemByPK = ({
  PK,
  reload = true,
  dictionary,
  moreSteps,
  getDatesToRootLevelInCrtFormItem = false
}) => {
  return dynamoAction({
    method: "QUERY",
    parameters: {
      TableName: process.env.REACT_APP_TABLE_NAME,
      KeyConditionExpression: "#pk = :pkvalue",
      // IndexName: "SK-PK",
      ExpressionAttributeNames: {
        "#pk": "PK"
      },
      ExpressionAttributeValues: {
        ":pkvalue": { S: PK }
      }
    },
    onSuccess: (data, dispatch) => {
      dispatch(setApiResponse(data))
      const firstItem = data?.items?.[0]
      //this could be unnecessary in the future, 'cuz the form will automaticly send an empty value for thmbnl img and subttl
      if (
        (firstItem?.SK === "GUEST_ARTICLE_V2" ||
          firstItem?.SK === "JOB_RESPONSE") &&
        !firstItem?.values?.content?.find?.((v) => v.type === "subtitle")
      ) {
        firstItem?.values.content?.splice?.(1, 0, {
          type: "subtitle",
          id: "152",
          text: ""
        })
      }
      if (moreSteps) moreSteps(firstItem, dispatch)
      if (dictionary) {
        let payload = {}
        payload[dictionary] = data?.items
        return {
          type: SET_DICTIONARY,
          payload
        }
      } else {
        const basicPayload =     {
          ...(firstItem?.values ?? {}),
          PK: firstItem?.PK, //used to identify and opened Dynamo item
          SK: firstItem?.SK,
        }
        const parsedPayloadData = 
          getDatesToRootLevelInCrtFormItem
          ?
            {
              ...basicPayload,
              eDate: firstItem?.eDate,
              cDate: firstItem?.cDate,
            }
          :
            basicPayload
          return {
            type: SET_CRT_FORM_ITEM,
            payload: {
              data: parsedPayloadData
            }
          }
      }
    },
    onFailure: (err) => {
      printError("loadDynamoFormItemByPK()", err)
      // showMagicToaster({title: err})

      return {
        type: "a"
      }
    }
  })
}

export const loadDynamoFormItemByPK_v2 = ({
  input,
  reload = true,
  dictionary,
  moreSteps,
  setDvListProducts,
  combine = false
}) => {
  const PK = input?.split?.("|")?.[0]
  const SK = input?.split?.("|")?.[1] ?? getSKfromType(PK?.split?.("-")?.[0])

  return dynamoAction({
    method: "QUERY",
    parameters: {
      TableName: process.env.REACT_APP_TABLE_NAME,
      KeyConditionExpression: "PK = :pkvalue and SK = :skvalue",
      // IndexName: "SK-PK",
      // ExpressionAttributeNames: {
      //   "#pk": "PK",
      //   "#sk": "SK",
      // },
      ExpressionAttributeValues: {
        ":pkvalue": { S: PK },
        ":skvalue": { S: SK }
      }
    },
    onSuccess: (data, dispatch) => {
      dispatch(setApiResponse(data))
      const firstItem = data?.items?.[0]
      if (moreSteps) moreSteps(firstItem, dispatch)
      // debugger
      if (setDvListProducts) dispatch(setDataItems(data?.items?.[0]?.products, false, true))
      if (dictionary) {
        let payload = {}
        payload[dictionary] = data?.items
        return {
          type: SET_DICTIONARY,
          payload
        }
      } else
        return {
          type: SET_CRT_FORM_ITEM,
          payload: {
            data: firstItem,
            combine: combine
          }
        }
    },
    onFailure: (err) => {
      printError("loadDynamoFormItemByPK_v2()", err)
      // showMagicToaster({title: err})

      return {
        type: "a"
      }
    }
  })
}

export async function clearCookieToken() {
  const url = process.env.REACT_APP_API_ENDPOINT
  const rfToken = getRefreshToken()
  return await axiosApiInstance
    .post(`${url}/logout`, null, { params: {
      rftok: rfToken,
    }})
    .then((data) => {
      // debugger
      return data
    })
    .catch((error) => {
      console.info("Remove Token Error: ", error)
      // return Promise.reject(error)
    })
}