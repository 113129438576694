import comenzi from "views/invoices/comenzi/data"
import MagicTable from "components/MagicTable/MagicTable"
import { mockHeader } from "components/MagicTable/mockHeader"
import { useSelector } from "store"
import { useDispatch } from "store"
import { saveUser } from "store/modules/user"
import {
  loadDynamoFormItemByPK,
  loadDynamoItemsBySK,
  searchStuff
} from "store/modules/dataView.search"
import {
  getExternalRoles,
  getInternalRoles,
  loadDictionary,
  loadEnums,
  loadProductCategories,
  setLoginCalls
} from "store/modules/backData"
import SvgIcon from "components/SvgIcon"
import { useEffect } from "react"
import styled from "styled-components"
import { refreshBasket, saveProduct } from "store/modules/shop"
import { loadItem } from "store/justActions/loadApiIems"
import { resetDV, setDataViewId } from "store/modules/dataView"
import { saveApiItem } from "store/justActions/saveApiIems"
import { javaLogin, loadMe, refreshJavaToken } from "store/justActions/javaAuth"
import { useHistory } from "react-router-dom"

export const frontEndVersion = "V-0.7.50"

const Container = styled.div`
  button + button {
    margin-left: 1rem;
  }
  button {
    background-color: lightblue;
    border-radius: 4px;
    border: 1px solid blue;
    padding: 3px;
    margin-bottom: 1rem;
  }
  >div:last-child
    /* >div:nth-last-child(2) */ {
    background-color: white;
    min-height: 500px;
    border-radius: 8px;
    padding: 1.5rem;
    font-size: 12px;
    margin-top: 1rem;
  }
`
const SorinsPage2 = () => {
  const dispatch = useDispatch()
  const apiResponse = useSelector((state) => state.auth.apiResponse)
  const loginCalls = useSelector((state) => state.backData?.loginCalls)
  const path = window.location.pathname?.split?.("/")?.slice?.(-1)?.[0]
  const history = useHistory()
  useEffect(() => {
    const path = window.location.pathname?.split?.("/")?.slice?.(-1)?.[0]

    // dispatch(clearApiResponse())
    dispatch(resetDV())
  }, [])

  // useEffect(() => {
  //     if (loginCalls >= 5) {
  //         dispatch(setLoginCalls(0))
  //             setTimeout(() => {
  //                 history?.push?.('/admin/product/list')
  //             }, 3000);
  //             }
  //     return () => {
  //         // dispatch(setLoginCalls(0))
  //     }
  // }, [loginCalls])

  return (
    <Container>
      {frontEndVersion}
      <div data-test-id='zoy8'>
        {/* {"loginName": "uniprest@test.com", "password": "Password123!!!!", "client": "front"} */}

        <button 
          data-test-id='S3Hn'
          onClick={() =>
            dispatch(
              javaLogin("barna.szabo@magicsolutions.ro", "Password123!!!!")
            )
          }
        >
          login with Java
        </button>
        <button 
          data-test-id='IgQ8'
          onClick={() =>
            dispatch(javaLogin("uniprest@test.com", "Password123!!!!"))
          }
        >
          login with uniprest@test.com
        </button>
        <button 
          data-test-id='6ac2'
          onClick={() =>
            dispatch(javaLogin("corina.ceuca@uniprest.ro", "Password123!!!!"))
          }
        >
          login with corina.ceuca@uniprest.ro
        </button>
        <button data-test-id='NXOs' onClick={() => dispatch(javaLogin("admi", "Password123!!!!"))}>
          login with admi
        </button>
        <button data-test-id='N1BO' onClick={() => dispatch(javaLogin("bsur", "Password123!!!!"))}>
          login with bsur
        </button>
        <button data-test-id='MjIu' onClick={() => dispatch(javaLogin("mktm", "Password123!!!!"))}>
          login with mktm
        </button>
        <button data-test-id='pZZu' onClick={() => dispatch(javaLogin("rfpm", "Password123!!!!"))}>
          login with rfpm
        </button>
        <button data-test-id='BwWC' onClick={() => dispatch(javaLogin("accm", "Password123!!!!"))}>
          login with accm
        </button>
        <button data-test-id='efH2' onClick={() => dispatch(javaLogin("bosa", "Password123!!!!"))}>
          login with bosa
        </button>
        <button data-test-id='hqw8' onClick={() => dispatch(javaLogin("cadm1", "Password123!!!!"))}>
          login with cadm1
        </button>
        <button data-test-id='jVeW' onClick={() => dispatch(javaLogin("cadm2", "Password123!!!!"))}>
          login with cadm2
        </button>
        <button data-test-id='rraZ' onClick={() => dispatch(javaLogin("uadm1", "Password123!!!!"))}>
          login with uadm1
        </button>
        <button data-test-id='FzBh' onClick={() => dispatch(javaLogin("uadm2", "Password123!!!!"))}>
          login with uadm2
        </button>
        <button data-test-id='81U2' onClick={() => dispatch(javaLogin("gues", "Password123!!!!"))}>
          login with gues
        </button>
        {/* <button 
          data-test-id='1m6I'
          onClick={() =>
            dispatch(javaLogin("anonymous", "Password123!!!!", true))
          }
        >
          login with anonymous
        </button> */}
        <button data-test-id='3phW' onClick={() => dispatch(loadMe())}>load me</button>
        <button data-test-id='N7Ot' onClick={() => dispatch(refreshJavaToken())}>
          refresh topken
        </button>
        <button 
          data-test-id='s8Jz'
          onClick={() => dispatch(loadEnums("product.ProductStockStatus"))}
        >
          loadEnums product.ProductStockStatus
        </button>
        <button data-test-id='fNOV' onClick={() => dispatch(loadEnums("user.UserStatus"))}>
          loadEnums user.UserStatus NW
        </button>
        <button data-test-id='m2aM' onClick={() => dispatch(loadDictionary("PRODUCT_BRAND"))}>
          loadDictionary PRODUCT_BRAND
        </button>
        <button data-test-id='gk2w' onClick={() => dispatch(loadProductCategories())}>
          loadProductCategories PRODUCT_BRAND
        </button>
        <button data-test-id='7aEn' onClick={() => dispatch(setDataViewId())}>
          reset data view id
        </button>
        <button data-test-id='dDdq' onClick={() => dispatch(refreshBasket())}>
          refresh basket
        </button>
      </div>
      <div data-test-id='03rz'>
        <span data-test-id='6vr1' className="mr4">Audit log</span>
        <button data-test-id='IIMH' onClick={() => dispatch(getExternalRoles())}>
          get external roles
        </button>
        <button data-test-id='fHnI' onClick={() => dispatch(getInternalRoles())}>
          get internal roles
        </button>
      </div>
      <div data-test-id='z8Yc'>
        <span data-test-id='fJar' className="mr4">Audit log</span>
        <button data-test-id='TEQk' onClick={() => dispatch(searchStuff({ type: "auditlog" }))}>
          AuditLog search
        </button>
        <button 
          data-test-id='7oCm'
          onClick={() => dispatch(searchStuff({ type: "invoice/my-invoice" }))}
        >
          invoice/my-invoice
        </button>
        <button 
          data-test-id='DPLv'
          onClick={() => dispatch(searchStuff({ type: "clientInvoice" }))}
        >
          clientInvoice
        </button>
      </div>
      <div data-test-id='IZq7'>
        <span data-test-id='2zxA' className="mr4">Questions</span>
        <button 
          data-test-id='yN7j'
          onClick={() => dispatch(loadItem("product-question", "5l7vZR"))}
        >
          Load question 5l7vZR
        </button>
        <button data-test-id='K5ng' onClick={() => dispatch(loadItem("product-answer", "5l7vZR"))}>
          Load answer 5l7vZR
        </button>
        <button 
          data-test-id='DoW3'
          onClick={() => dispatch(searchStuff({ type: "product-question" }))}
        >
          search questions
        </button>
        <button 
          data-test-id='sInI'
          onClick={() =>
            dispatch(
              saveApiItem({ type: "product-answer", data: mockAnswerData })
            )
          }
        >
          Save Mock Answer
        </button>
        <button 
          data-test-id='HxbP'
          onClick={() =>
            dispatch(
              saveApiItem({ type: "product-question", data: MockAnswerData })
            )
          }
        >
          Save Mock Question
        </button>
      </div>
      <div data-test-id='2Ohn'>
        <span data-test-id='SBq9' className="mr4">Badge</span>
        <button data-test-id='Gd3C' onClick={() => dispatch(loadItem("goods-badge", "5l7vZR"))}>
          Load badge 5l7vZR
        </button>
        <button data-test-id='vmeN' onClick={() => dispatch(searchStuff({ type: "goods-badge" }))}>
          search badge
        </button>
        <button 
          data-test-id='6jFm'
          onClick={() =>
            dispatch(saveApiItem({ type: "goods-badge", data: mockBadgeData }))
          }
        >
          Save Mock Badge
        </button>
      </div>
      <div data-test-id='Zg8O'>
        <span data-test-id='TV5x' className="mr4">Product rating</span>
        <button data-test-id='3ziE' onClick={() => dispatch(loadItem("product-rating", "5l7vZR"))}>
          Load discount-rating 5l7vZR
        </button>
        <button 
          data-test-id='bNBz'
          onClick={() =>
            dispatch(
              saveApiItem({
                type: "product-rating",
                data: mockDiscountRatingData
              })
            )
          }
        >
          Save Mock discount-rating
        </button>
        <button 
          data-test-id='xjC5'
          onClick={() => dispatch(searchStuff({ type: "product-rating" }))}
        >
          Search discount-rating
        </button>
      </div>
      <div data-test-id='9UMu'>
        <span data-test-id='soVA' className="mr4">Location</span>
        <button 
          data-test-id='pJdD'
          onClick={() => dispatch(loadItem("organization/workplace", "Lmnqln"))}
        >
          Load location WmGwm7
        </button>
        <button 
          data-test-id='q2Zn'
          onClick={() =>
            dispatch(
              saveApiItem({
                type: "organization/workplace",
                data: saveUniLocationData
              })
            )
          }
        >
          Save Mock location
        </button>
        <button 
          data-test-id='ebP9'
          onClick={() =>
            dispatch(
              searchStuff({
                type: "organization/workplace",
                sortField: null,
                reload: true
              })
            )
          }
        >
          Search Location
        </button>
      </div>
      <div data-test-id='5rQ8'>
        <span data-test-id='DL84' className="mr4">Documents</span>
        <button 
          data-test-id='eSY6'
          onClick={() => dispatch(loadItem("product-document", "WmGwm7"))}
        >
          Load document WmGwm7
        </button>
        <button 
          data-test-id='vdCs'
          onClick={() =>
            dispatch(
              saveApiItem({ type: "product-document", data: mockDocumentData })
            )
          }
        >
          Save Mock document
        </button>
        <button 
          data-test-id='eoR6'
          onClick={() => dispatch(searchStuff({ type: "product-document" }))}
        >
          Search documents
        </button>
      </div>
      <div data-test-id='mnDY'>
        <span data-test-id='MwpX' className="mr4">Warehouse info</span>
        <button 
          data-test-id='rjDX'
          onClick={() => dispatch(loadItem("organization/warehouse", "WmGwm7"))}
        >
          Load warehouse WmGwm7
        </button>
        <button 
          data-test-id='BO0U'
          onClick={() =>
            dispatch(
              saveApiItem({
                type: "organization/warehouse",
                data: mockWarehouseData
              })
            )
          }
        >
          Save Mock warehouse
        </button>
        <button 
          data-test-id='Z3Jp'
          onClick={() =>
            dispatch(searchStuff({ type: "organization/warehouse" }))
          }
        >
          Search warehouse
        </button>
        <button data-test-id='xcma' onClick={() => dispatch(searchStuff({ type: "stock" }))}>
          Search stock
        </button>
      </div>
      <div data-test-id='SO5c'>
        <span data-test-id='dxSm' className="mr4">Basket info</span>
        <button data-test-id='OKKg' onClick={() => dispatch(loadItem("basket", "5l7vZR"))}>
          Load basket 5l7vZR
        </button>
        <button 
          data-test-id='fmRS'
          onClick={() =>
            dispatch(saveApiItem({ type: "basket", data: mockBasketData }))
          }
        >
          Save Mock basket
        </button>
        <button data-test-id='d586' onClick={() => dispatch(searchStuff({ type: "basket" }))}>
          Search basket
        </button>
      </div>

      {/*
            AuditLog -> Postman/Uniprest/AuditLog
            */}

      <div data-test-id='wAsC'>
        <span data-test-id='i4jg' className="mr2">Discount: Grid</span>
        <button 
          data-test-id='OmvG'
          onClick={() =>
            dispatch(loadItem("product/goods/discount/grid", "5l7vZR"))
          }
        >
          Load discount-grid 5l7vZR
        </button>
        <button 
          data-test-id='Rmsl'
          onClick={() =>
            dispatch(
              saveApiItem({
                type: "product/goods/discount/grid",
                data: mockDiscountGridData
              })
            )
          }
        >
          Save Mock discount-grid
        </button>
        <button 
          data-test-id='AT0q'
          onClick={() =>
            dispatch(searchStuff({ type: "product/goods/discount/grid" }))
          }
        >
          Search discount-grid
        </button>

        <span data-test-id='ef7j' className="mr2 ml4">Volume</span>
        <button 
          data-test-id='y3tM'
          onClick={() =>
            dispatch(loadItem("product/goods/discount/volume", "5l7vZR"))
          }
        >
          Load discount-volume 5l7vZR
        </button>
        <button 
          data-test-id='Zg2X'
          onClick={() =>
            dispatch(
              saveApiItem({
                type: "product/goods/discount/volume",
                data: mockDiscountVolumeData
              })
            )
          }
        >
          Save Mock discount-volume
        </button>
        <button 
          data-test-id='dni6'
          onClick={() =>
            dispatch(searchStuff({ type: "product/goods/discount/volume" }))
          }
        >
          Search discount-volume
        </button>

        <span data-test-id='J2w4' className="mr2 ml4">Package</span>
        <button 
          data-test-id='ethB'
          onClick={() =>
            dispatch(loadItem("product/goods/discount/package", "5l7vZR"))
          }
        >
          Load discount-package 5l7vZR
        </button>
        <button 
          data-test-id='eI1g'
          onClick={() =>
            dispatch(
              saveApiItem({
                type: "product/goods/discount/package",
                data: mockDiscountPackageData
              })
            )
          }
        >
          Save Mock discount-package
        </button>
        <button 
          data-test-id='5x5L'
          onClick={() =>
            dispatch(searchStuff({ type: "product/goods/discount/package" }))
          }
        >
          Search discount-package
        </button>

        <span data-test-id='svrg' className="mr2 ml4">Fix</span>
        <button 
          data-test-id='hPNX'
          onClick={() =>
            dispatch(loadItem("product/goods/discount/fix", "5l7vZR"))
          }
        >
          Load discount-fix 5l7vZR
        </button>
        <button 
          data-test-id='t6xs'
          onClick={() =>
            dispatch(
              saveApiItem({
                type: "product/goods/discount/fix",
                data: mockDiscountFixData
              })
            )
          }
        >
          Save Mock discount-fix
        </button>
        <button 
          data-test-id='fDZl'
          onClick={() =>
            dispatch(searchStuff({ type: "product/goods/discount/fix" }))
          }
        >
          Search discount-fix
        </button>
      </div>
      <div data-test-id='KmZv'>
        <span data-test-id='dwF1' className="mr4">User</span>
        <button data-test-id='Rrbs' onClick={() => dispatch(loadItem("user", "5l7vZR"))}>
          Load User 5l7vZR
        </button>
        {/* TODO: why is working both search query? */}
        <button 
          data-test-id='Ab4R'
          onClick={() => dispatch(searchStuff({ type: "uniprest/user" }))}
        >
          search users
        </button>
        <button data-test-id='PQBd' onClick={() => dispatch(searchStuff({ type: "user" }))}>
          search2 users
        </button>
        <button data-test-id='zCOF' onClick={() => dispatch(saveUser(saveUserData))}>
          Save User
        </button>
      </div>
      <div data-test-id='Ea1x'>
        <span data-test-id='s2Ru' className="mr4">Group</span>
        <button data-test-id='ta2K' onClick={() => dispatch(loadItem("group", "5l7vZR"))}>
          Load group 5l7vZR
        </button>
        <button 
          data-test-id='TIc9'
          onClick={() =>
            dispatch(
              searchStuff({
                type: "uniprest/group",
                uniprest: true,
                refresh: true
              })
            )
          }
        >
          Search group
        </button>
      </div>
      <div data-test-id='tpiK'>
        <button 
          data-test-id='Tos5'
          onClick={() =>
            dispatch(loadItem("organization/webshop-client", "yZR7Nl"))
          }
        >
          Load webshop-client yZR7Nl
        </button>
        <button 
          data-test-id='suYR'
          onClick={() =>
            dispatch(searchStuff({ type: "organization/webshop-client" }))
          }
        >
          search organizations new
        </button>
      </div>

      <div data-test-id='qMhw'>
        <div data-test-id='Kk0V' className="etype-3">Goods</div>
        <button 
          data-test-id='bkEF'
          onClick={() => dispatch(searchStuff({ type: "product/goods" }))}
        >
          search Goods
        </button>
        <button 
          data-test-id='PT6w'
          onClick={() =>
            dispatch(
              searchStuff({ type: "product/goods", searchTerm: "TEAVA" })
            )
          }
        >
          search products by text "TEAVA"
        </button>
        <button data-test-id='M02O' onClick={() => dispatch(loadItem("product/goods", "rqxeQm"))}>
          Load Good rqxeQm
        </button>
        <button data-test-id='aWkj' onClick={() => dispatch(saveProduct(saveProductData))}>
          ADD Product
        </button>
      </div>
      <div data-test-id='UDMx'>
        Load dynamo by sort key
        <button 
          data-test-id='2v1T'
          onClick={() =>
            dispatch(loadDynamoItemsBySK({ SK: "SIGNUP_RESPONSE" }))
          }
        >
          loadDynamoType SIGNUP_RESPONSE
        </button>
        <button 
          data-test-id='N59N'
          onClick={() =>
            dispatch(loadDynamoItemsBySK({ SK: "CONTACT_RESPONSE" }))
          }
        >
          loadDynamoType CONTACT_RESPONSE
        </button>
        <button 
          data-test-id='qDLS'
          onClick={() =>
            dispatch(loadDynamoItemsBySK({ SK: "CAREER_RESPONSE" }))
          }
        >
          loadDynamoType CAREER_RESPONSE
        </button>
      </div>
      <div data-test-id='GFZU'>
        load dynamo by partition key
        <button 
          data-test-id='l2su'
          onClick={() =>
            dispatch(loadDynamoFormItemByPK({ PK: "CFR-4pi3b61t" }))
          }
        >
          LOAD CFR-4pi3b61t
        </button>
      </div>
      <div data-test-id='vT9v'>
        <button data-test-id='mFug' onClick={() => dispatch(loadItem("productDetails", "rqxeQm"))}>
          Test Composite Load
        </button>
      </div>
      {/* <div data-test-id='IrIw'><pre data-test-id='QuJ9'>{JSON.stringify({apiResponse:"dsd"}, null, 2) }</pre></div> */}
      <div data-test-id='qYa1'>
        <pre data-test-id='xs5M'>{JSON.stringify(apiResponse, null, 2)}</pre>
      </div>
      {/* <img data-test-id='gVs6' src={samplePicUrl} alt="img"/> */}
    </Container>
  )
}

export default SorinsPage2

const saveUniLocationData = {
  id: "Lmnqln",
  type: "WORKPLACE",
  name: "Depozit Central",
  code: "365",
  alias: null,
  status: "OrganizationStatus.ACTIVE",
  addressList: [
    {
      id: "nljdrx",
      type: "HEADQUARTER",
      country: "RO",
      county: "MS",
      locationId: "rwPW3Z",
      street: "Str. Baneasa",
      number: "Nr. 8",
      zipCode: null,
      region: null,
      displayAddress: "Str. Baneasa, Nr. 8",
      extern: false,
      longitude: null,
      latitude: null,
      email: null,
      mobil: null,
      phone: null
    }
  ],
  bankAccountList: [],
  contactList: [],
  contactInfo: {
    email: null,
    mobil: null,
    phone: null
  },
  description: null,
  presentationList: [],
  attachmentList: [],
  limitList: [],
  operatingHourList: [],
  hasAccess: false
}

const saveUserData = {
  accessKeyList: [],
  id: null,
  language: "RO",
  loginName: null,
  name: "Uniprest user",
  newLoginName: "uniprest@test.com",
  attachmentList: [],
  avatar: null,
  contactInfo: null,
  department: "TRANSPORT",
  groupIds: ["XlDbrD"],
  organizationId: "0lYvRm",
  phone: null,
  position: "08",
  reminderList: [],
  status: "UserStatus.ACTIVE",
  userDirectory: "INTERN",
  workplace: null
}

const saveProductData = {
  id: null,
  status: "ACTIVE",
  type: "PRODUCT",
  code: "code123",
  name: "name123",
  brandName: "brandName123",
  language: "RO",
  summary: null,
  description: null,
  unitOfMeasure: null,
  categoryId: "rdxKzr",
  hashtagList: null,
  attachmentList: [],
  nameAlternative: "",
  groupCode: "",
  stockable: false,
  sellQuantityMinim: 0,
  sellQuantityIncrement: 0,
  lastUpdateTime: null
}

const mockAnswerData = {
  id: "arBbr0",
  type: "ANSWER",
  answer: "My Answer Edit.",
  attachemntList: null,
  userName: "Uniprest user",
  organizationName: "UNIPREST",
  answerTime: "2021-06-22T12:13:18.692+0300",
  questionId: "5l7vZR"
}

const mockDocumentData = {
  id: "WmGwm7",
  status: "ACTIVE",
  name: "docTest1",
  description: "docDescriptionTest1",
  category: "TECHNICAL",
  attachment: null,
  validity: "2021-06-22T09:20:29.339+0300"
}

const MockAnswerData = {
  id: "5l7vZR",
  status: "ACTIVE",
  userName: "Uniprest user",
  organizationName: "UNIPREST",
  question: "TesztMentes van kerdes?2",
  productId: "3r27pZ",
  answerList: [
    {
      id: "5l7vZR",
      type: "ANSWER",
      answer: "TesztValasz -- Valasz 1 elso kerdesre.",
      attachemntList: null,
      userName: "Uniprest user",
      organizationName: "UNIPREST",
      answerTime: "2021-06-21T14:31:30.620+0300",
      questionId: "5l7vZR",
      ratingId: null
    },
    {
      id: "WmGwm7",
      type: "ANSWER",
      answer: "TesztValasz -- Valasz 1 elso kerdesre.",
      attachemntList: null,
      userName: "Uniprest user",
      organizationName: "UNIPREST",
      answerTime: "2021-06-21T14:45:21.688+0300",
      questionId: "5l7vZR",
      ratingId: null
    },
    {
      id: "arBbr0",
      type: "ANSWER",
      answer: "My Answer Edit.",
      attachemntList: null,
      userName: "Uniprest user",
      organizationName: "UNIPREST",
      answerTime: "2021-06-24T11:46:31.030+0300",
      questionId: "5l7vZR",
      ratingId: null
    }
  ]
}

const mockDiscountGridData = {
  id: "5l7vZR",
  status: "ACTIVE",
  productGroup: "01",
  clientList: [
    {
      id: "5l7vZR",
      clientId: "5l7vZR",
      discountGridId: "5l7vZR"
    }
  ],
  discount: 12,
  global: false
}

const mockDiscountPackageData = {
  id: "5l7vZR",
  status: "ACTIVE",
  name: "package1",
  quantityStock: 1500.0,
  quantitySold: 1200.0,
  validityStart: "2021-06-21T14:36:57.000+0300",
  validityEnd: "2021-06-28T14:36:57.000+0300",
  global: false,
  goodsList: [
    {
      id: "5l7vZR",
      goodsId: "Oro5lN",
      quantity: 1.0,
      discount: 12.0
    }
  ],
  clientList: []
}

const mockDiscountFixData = {
  id: "5l7vZR",
  status: "ACTIVE",
  productId: "krdjql",
  clientId: "5l7vZR",
  price: 1436,
  currencyId: "5l7vZR"
}

const mockDiscountVolumeData = {
  id: "5l7vZR",
  status: "ACTIVE",
  productId: "krdqZB",
  clientList: [],
  volume: 12.0,
  unitOfMeasure: "CUTIE",
  discount: 12.0,
  global: false
}

const mockDiscountRatingData = {
  id: "5l7vZR",
  status: "ACTIVE",
  userName: "Uniprest user",
  organizationName: "UNIPREST",
  review: "blabla22",
  rating: 5,
  reviewTime: "2021-06-22T09:20:29.339+0300",
  productId: "ElLDPl",
  answerList: [
    {
      id: "krwylx",
      type: "ANSWER",
      answer: "Valasz 1222 elso kerdesre.",
      attachemntList: null,
      userName: "Uniprest user",
      organizationName: "UNIPREST",
      answerTime: "2021-06-22T09:44:38.506+0300",
      questionId: null,
      ratingId: "5l7vZR"
    }
  ]
}

const mockWarehouseData = {
  id: "WmGwm7",
  type: "WAREHOUSE",
  name: "Warehouse12",
  code: "wareh",
  alias: null,
  status: "ACTIVE",
  addressList: [],
  bankAccountList: [],
  contactList: [],
  description: null,
  presentationList: [],
  attachmentList: [],
  limitList: [],
  operatingHourList: [],
  hasAccess: false
}

const mockBasketData = {
  id: "5l7vZR",
  status: "ACTIVE",
  code: "code123",
  clientId: "5l7vZR",
  deliveryAddressId: "5l7vZR",
  paymentMethodType: "BANK_CARD",
  lineList: [
    {
      id: "5l7vZR",
      goodsId: "5l7vZR",
      quantity: 22.0,
      unitOfMeasure: "TNE",
      vat: 11.0,
      unitPriceNet: 21.0,
      unitPriceVat: 22.0,
      unitPriceBrut: 33.0,
      discount: 11.0,
      valueNet: 2.0,
      valueVat: 3.0,
      valueBrut: 4.0,
      currencyId: "5l7vZR",
      info: "aaaaainfo123444"
    }
  ],
  totalValueNet: 22.0,
  totalValueVat: 33.0,
  totalValueBrut: 44.0,
  shippingCostBrut: null,
  currencyId: "5l7vZR",
  info: "info123444"
}

const mockBadgeData = {
  id: "5l7vZR",
  name: "Deal of the week",
  description: "details of the badge",
  color: "#FF00FF"
}
